import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { getBrowserGlobals } from "core/utils";
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import { useEffect } from "react";
import { LOCAL_STORAGE_KEYS } from "constants/localStorage";
import {
  clearAuthLocalStorage,
  clearAuthSessionStorage,
  getLocalStorageItem,
} from "utilities/storage";
import { useQuery } from "@tanstack/react-query";
import {
  extraQueryParameters,
  loginScopes,
  tokenCacheDurationMs,
} from "./config";

export type UseAuthReturn = {
  login: () => void;
  logout: (calledByThisInstance?: boolean) => void;
  inProgress: boolean;
};

export const useAuth = (): UseAuthReturn => {
  useSilentLogin();
  const login = useLogin();
  const logout = useLogout();
  const inProgress = useLoginInProgress();

  return { login, logout, inProgress };
};

export const useLogoutListener = (): void => {
  const logout = useLogout();

  useEffect(() => {
    getBrowserGlobals()?.window.addEventListener(
      "storage",
      (event: StorageEvent) => {
        if (event.key === LOCAL_STORAGE_KEYS.logoutCount) {
          logout(false);
        }
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useToken = (): AuthenticationResult | null => {
  const { instance } = useMsal();
  const msalAccount = instance.getAllAccounts()[0] ?? null;

  const { data } = useQuery(
    ["acquireTokenSilent"],
    () =>
      instance
        .acquireTokenSilent({
          account: msalAccount,
          scopes: loginScopes,
        })
        .then(
          (token) => token,
          () =>
            instance.acquireTokenPopup({
              account: msalAccount,
              scopes: loginScopes,
            })
        ),
    { enabled: !!msalAccount, staleTime: tokenCacheDurationMs }
  );

  return data ?? null;
};

const useLogin = (): (() => void) => {
  const inProgress = useLoginInProgress();
  const { instance } = useMsal();

  return () => {
    if (!inProgress) {
      clearAuthLocalStorage();
      clearAuthSessionStorage();
      instance.loginRedirect({
        scopes: loginScopes,
        extraQueryParameters,
      });
    }
  };
};

const useSilentLogin = () => {
  useMsalAuthentication(InteractionType.Silent, {
    scopes: loginScopes,
  });
};

const useLoginInProgress = () => {
  const { inProgress } = useMsal();

  return inProgress !== InteractionStatus.None;
};

const useLogout = () => {
  const { instance } = useMsal();

  return (calledByThisInstance = true) => {
    const currentLogoutCount = getLocalStorageItem(
      LOCAL_STORAGE_KEYS.logoutCount
    );
    const newLogoutCount = (Number(currentLogoutCount) + 1).toString();

    if (calledByThisInstance) {
      clearAuthLocalStorage();
      localStorage.setItem(LOCAL_STORAGE_KEYS.logoutCount, newLogoutCount);
    }

    instance.logoutRedirect();
  };
};
