import { getBrowserGlobals } from "core/utils";
import { useEffect } from "react";
import { dataLayerPush } from "../utilities/analytics";

const useAnchorTracking = () => {
  const windowObject = getBrowserGlobals()?.window;

  useEffect(() => {
    const onAnchorClick = (event) => {
      const anchor = event.target.closest("a");

      if (anchor) {
        const href = anchor.getAttribute("href") as string;

        if (href.startsWith("tel:")) {
          dataLayerPush({
            event: "user_interactions",
            eventName: "phone_call",
            action: "click",
          });
        } else if (href.startsWith("mailto:") && href[7] !== "?") {
          // Filter out mailto links without recipients
          dataLayerPush({
            event: "user_interactions",
            eventName: "email_click",
            action: "click",
          });
        }
      }
    };

    windowObject?.addEventListener("click", onAnchorClick);
    return () => {
      windowObject?.removeEventListener("click", onAnchorClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAnchorTracking;
