import { getBrowserGlobals } from "core/utils";
import { EventData } from "../types";

export const dataLayerPush = (eventData: EventData) => {
  const browserWindow = getBrowserGlobals()?.window;
  if (!browserWindow) return;
  browserWindow.dataLayer = browserWindow?.dataLayer || [];
  if (eventData.label) browserWindow.dataLayer.push(eventData);
  else
    browserWindow.dataLayer.push({
      event: eventData.event,
      eventName: eventData.eventName,
      action: eventData.action,
  });
};