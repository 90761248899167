import { getBrowserGlobals } from "core/utils";

export const getLocalStorageItem = (
  key: string
): string | Record<string, string> => {
  const value =
    getBrowserGlobals().window && localStorage && localStorage.getItem(key);
  if (!value) {
    return null;
  }
  return JSON.parse(value);
};

export const clearAuthLocalStorage = (): void => {
  if (getBrowserGlobals()?.window && localStorage) {
    localStorage.clear();
  }
};

export const getSessionStorageItem = (
  key: string
): string | Record<string, string> => {
  const value =
    getBrowserGlobals()?.window &&
    sessionStorage &&
    sessionStorage.getItem(key);
  if (!value) {
    return null;
  }
  return JSON.parse(value);
};

export const clearAuthSessionStorage = (): void => {
  if (getBrowserGlobals()?.window && sessionStorage) {
    sessionStorage.clear();
  }
};
