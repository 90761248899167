import { ReactNode } from "react";
import useAnchorTracking from "../../../hooks/useAnchorTracking";

const AnchorTracker = ({ children }) => {
  useAnchorTracking();
  return children;
};
type PageElementProps = {
  children: ReactNode;
};

export const PageElement = ({ children }: PageElementProps) => (
  <AnchorTracker>{children}</AnchorTracker>
);
