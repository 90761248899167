import { Urls } from "constants/enums";

export function serialize(obj) {
  const str = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const p in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join("&");
}

export function loadGoogleRecaptcha(callback: () => void) {
  const loadedScript = getBrowserGlobals()?.document.getElementById(
    "google-recaptcha-v3"
  );

  if (!loadedScript) {
    const script = getBrowserGlobals()?.document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHAV3_SITE_KEY}`;
    script.id = "google-recaptcha-v3";
    script.type = "application/javascript";
    getBrowserGlobals()?.document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (loadedScript && callback) callback();
}

export function isEnabled(str: string) {
  return str && str.toLowerCase() === "enabled";
}

export function toSlug(str: string) {
  return str
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

export function pad2(number: number) {
  return (number < 10 ? "0" : "") + number;
}

export function getMenuItemPath(menuItem) {
  const anchorPath = menuItem.anchor ? `#${menuItem.anchor}` : "";

  return `/${menuItem.pageLink.slug}${anchorPath}`;
}

export function isNewsCategoriesPage(str: string) {
  return str.startsWith(Urls.NEWS_CATEGORIES_URL);
}

export function isPagePage(str: string) {
  return str.includes(Urls.PAGE_URL);
}

export function getCategoryFromUrl(str: string) {
  if (isNewsCategoriesPage(str)) {
    const strParts = str.split("/").filter(Boolean);

    if (isPagePage(str)) {
      return strParts[strParts.length - 3];
    }

    return strParts[strParts.length - 1];
  }
  return null;
}

export function getPageNumberFromUrl(str: string) {
  if (isPagePage(str)) {
    const strParts = str.split("/").filter(Boolean);

    return strParts[strParts.length - 1];
  }
  return null;
}

export const isUrlExternal = (link: string) => /^((https?:)|(www.))/.test(link);
export const isMailtoOrTel = (link: string) => /^(mailto|tel):/.test(link);

/**
 * Gets browser builtin globals in a server-side-rendering-safe way
 *
 * @returns The globals if in a browser environment, or undefined if in a
 * SSR environment
 */
export function getBrowserGlobals() {
  // eslint-disable-next-line no-restricted-globals
  return typeof window === "undefined" ? undefined : { window, document };
}
