import { GatsbyBrowser } from "gatsby";
import React from "react";
import RootElement from "./src/components/elements/RootElement";
import PageElement from "./src/components/elements/PageElement";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <RootElement>{element}</RootElement>;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => <PageElement>{element}</PageElement>;
