import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import { useLogoutListener } from "utilities/auth";
import { msalInstance } from "utilities/auth/config";
import { ModalProvider } from "../DonateModal/ModalContext";

const LogoutListener = ({ children }) => {
  useLogoutListener();

  return children;
};

const queryClient = new QueryClient();

type RootElementProps = {
  children: ReactNode;
};

export const RootElement = ({ children }: RootElementProps) => (
  <QueryClientProvider client={queryClient}>
    <MsalProvider instance={msalInstance}>
      <LogoutListener>
        <ModalProvider>{children}</ModalProvider>
      </LogoutListener>
    </MsalProvider>
  </QueryClientProvider>
);
