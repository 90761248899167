// Person
export enum PersonBadge {
  DECEASED = "Deceased",
}

export const PersonBadgeIcon = {
  [PersonBadge.DECEASED]: "icon-asterisk",
};

export enum PersonType {
  REGULAR = "regular",
  DONOR = "donor",
}

// Text
export enum Alignment {
  CENTER = "Center",
  LEFT = "Left",
  RIGHT = "Right",
  BACKGROUND = "Background",
}

export enum Size {
  SMALL = "small",
  MEDIUM = "medium",
  NORMAL = "normal",
  LARGE = "large",
}

// Section layout
export enum SectionLayout {
  FULL = "Full Width",
  MEDIUM = "Medium Width",
  GRID = "grid",
  BLOCK = "Homepage Block",
  SINGLE_BLOCK = "Single Block",
}

export enum ContentPosition {
  BOTTOM = "Bottom",
}

// News
export enum NewsBoxTypes {
  ALL = "All news",
  NEWEST_THREE = "3 newest articles",
}

// Urls
export enum Urls {
  NEWS_CATEGORIES_URL = "/newscategories",
  NEWS_URL = "/news",
  PAGE_URL = "/page",
  OUR_MISSION_URL = "/our-mission",
  WAYS_TO_GIVE_URL = "/ways-to-give",
  FAQ = "/faq",
  LEADERSHIP = "/leadership",
  DONOR_RECOGNITION = "/donor-recognition",
}

// Contentful types
export enum ContentfulContentTypes {
  PERSON = "ContentfulPerson",
  TEXT = "ContentfulText",
  LIST_BUTTON = "ContentfulListButton",
  TEXT_BOX_HIGHLIGHT = "ContentfulTextBoxHighlight",
  TEXT_BOX_IMAGE = "ContentfulTextBoxImage",
  TEXT_BOX_VIDEO = "ContentfulTextBoxVideo",
  TEXT_BOX_ICON = "ContentfulTextBoxIcon",
  HIGHLIGHT_IMAGE = "ContentfulHighlightImage",
  LIST = "ContentfulList",
  EXPANDING_BLOCK = "ContentfulExpandingBlock",
  ANNUAL_REPORT_DOWNLOAD_BOX = "ContentfulAnnualReportDownloadBox",
  NEWS_ARTICLE = "ContentfulNewsArticle",
  ALLOCATION_OF_FUNDS = "ContentfulTextBoxImageAllocationOfFunds",
  GRANT = "ContentfulGrant",
  NEWS_BOX = "ContentfulNewsBox",
  FORM = "ContentfulForm",
  TWO_STEPS_IMAGE = "Contentful2StepsImage",
  TABS_NAVIGATION = "ContentfulTabsNavigation",
  SLIDE = "ContentfulSlide",
  CATEGORY = "ContentfulExcaliburSocietyCategory",
  HERO_SECTION = "ContentfulHeroBanner",
  SECTION_BOX = "ContentfulSectionBox",
  LISTS_CATEGORIES = "ContentfulListsCategories",
  MAP = "ContentfulMap",
  VISION_VALUES = "ContentfulVisionValues",
  IMAGES_LIST = "ContentfulImagesListText",
}

export enum ContentfulListTypes {
  PEOPLE_GRID = "People grid",
  NEWS_GRID = "News grid",
  ACCORDION = "Accordion",
  GRANTS_GRID = "Grants grid",
  SPONSORS_SLIDESHOW = "Sponsors slideshow",
  HOMEPAGE_SLIDESHOW = "Homepage slideshow",
  GRANTS_SLIDESHOW_1 = "Grants slideshow (1 item per page)",
  MAJOR_DONORS = "Major Donors",
  DONOR_RECOGNITION = "About donors list",
  PAST_PRESIDENTS = "Past Presidents",
  PEOPLE_GRID_2 = "People grid (4 item per page)",
}

export enum ContentfulFormTypes {
  CONTACT = "Contact",
  DONATE = "Donate",
}

export enum DateTime {
  DATE_FORMAT = "MMMM d, yyyy",
}

export enum Background {
  WHITE = "White",
}

export enum Layout {
  PORTRAIT = "Portrait",
  LANDSCAPE = "Landscape",
}

export enum Color {
  DEFAULT = "default",
  LIGHT = "light",
}

export enum ButtonVariant {
  ALTERNATIVE = "alternative",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TRANSPARENT = "transparent",
}

export enum SnackbarVariant {
  DEFAULT = "default",
  ERROR = "error",
}

export enum Direction {
  RIGHT = "right",
  LEFT = "left",
  DOWN = "down",
  UP = "up",
}

export enum IconSize {
  DEFAULT = "default",
  SMALL = "small",
}

export enum HeadingLevel {
  SECOND = "h2",
  THIRD = "h3",
}

export enum SectionVariant {
  SECONDARY = "Secondary",
  WHITE = "White",
  EQUAL_WIDTHS = "Equal Widths",
}

export enum ImageHeightVariant {
  FULL_HEIGHT = "Full Height",
}

export enum DisplayTitle {
  DISPLAY = "Display",
}

export enum LanguageName {
  "en" = "English",
  "es" = "Español",
  "zh-CN" = "简体中文",
  "zh-Hant" = "繁體中文",
  "id-ID" = "Indonesia",
  "ko-KR" = "한국어",
  "th" = "ไทย",
  "vi-VN" = "Tiếng Việt",
  "ja-JP" = "日本語",
}

export enum TitleSize {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
}
