import { PublicClientApplication } from "@azure/msal-browser";
import { getBrowserGlobals } from "core/utils";

export const msalConfig = {
  auth: {
    clientId: process.env.GATSBY_MSAL_CLIENT_ID ?? "",
    authority: process.env.GATSBY_MSAL_AUTHORITY ?? "",
    knownAuthorities: [process.env.GATSBY_MSAL_KNOWN_AUTHORITY ?? ""],
    redirectUri: getBrowserGlobals()?.window.location.origin
      ? getBrowserGlobals()?.window.location.origin
      : "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    iframeHashTimeout: +(process.env.GATSBY_IFRAMEHASHTIMEOUT ?? 30000),
    allowRedirectInIframe: true,
  },
};

export const loginScopes = ["openid", "offline_access"];
export const tokenCacheDurationMs = 10 * 60 * 1000;

export const extraQueryParameters = {
  vendorCode: "Foundation",
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance
  .handleRedirectPromise()
  .then((tokenResponse) => {
    if (tokenResponse?.account) {
      msalInstance.setActiveAccount(tokenResponse.account);
    }
  })
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.log("handleRedirectPromise error", error);
  });
